import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ClientWebhookSubscription, StoreWebhookSubscription, WebhookSubscription, WebhookTopic } from '../../shared/models/webhook.model';
import { CornerstoneApiResponse, HttpOptions } from '../models/http.model';
import { ErrorHandlerService } from './error-handler.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class WebhookService {
  private apiUrl = environment.nestApiUrl;
  private webhooks: WebhookSubscription[] = [];
  public options: HttpOptions;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private errorHandler: ErrorHandlerService
  ) {
    this.options = this.httpService.getHttpOptions();
  }

  getWebhooks(tenantId: string): Observable<WebhookSubscription[]> {
    return this.http
      .get<CornerstoneApiResponse<WebhookSubscription[]>>(`${this.apiUrl}/webhook?tenantId=${tenantId}`, this.options)
      .pipe(
        map(res => {
          this.webhooks = res.data;
          return res.data;
        }),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }

  createWebhook(tenantId: string, webhook: Partial<WebhookSubscription>): Observable<WebhookSubscription> {
    return this.http
      .post<CornerstoneApiResponse<WebhookSubscription>>(`${this.apiUrl}/webhook?tenantId=${tenantId}`, webhook, this.options)
      .pipe(
        map(res => res.data),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }

  updateWebhook(tenantId: string, id: number, data: Partial<WebhookSubscription>): Observable<WebhookSubscription> {
    return this.http
      .put<CornerstoneApiResponse<WebhookSubscription>>(`${this.apiUrl}/webhook/${id}`, data, this.options)
      .pipe(
        map(res => res.data),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }

  deleteWebhook(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/webhook/${id}`, this.options);
  }

  toggleTenantWebhook(tenantId: string, id: number): Observable<WebhookSubscription> {
    return this.http
      .patch<CornerstoneApiResponse<WebhookSubscription>>(`${this.apiUrl}/webhook/tenant/${tenantId}/subscription/${id}/toggle`, {}, this.options)
      .pipe(
        map(res => res.data),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }

  toggleClientWebhook(clientId: string, subscriptionId: number): Observable<ClientWebhookSubscription> {
    return this.http
      .patch<CornerstoneApiResponse<ClientWebhookSubscription>>(`${this.apiUrl}/webhook/clients/webhooks/${subscriptionId}/toggle`, {}, this.options)
      .pipe(
        map(res => res.data),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }

  toggleStoreWebhook(id: number): Observable<any> {
    return this.http.patch(`${this.apiUrl}/webhook/stores/webhooks/${id}/toggle`, {}, this.options);
  }

  isTopicUsed(topic: WebhookTopic): boolean {
    return this.webhooks.some(webhook => webhook.topic === topic);
  }

  getClientWebhookSubscriptions(clientId: string): Observable<{ data: ClientWebhookSubscription[] }> {
    return this.http.get<{ data: ClientWebhookSubscription[] }>(`${this.apiUrl}/webhook/clients/${clientId}/webhooks`, this.options);
  }

  getStoreWebhookSubscriptions(storeId: string): Observable<{ data: StoreWebhookSubscription[] }> {
    return this.http.get<{ data: StoreWebhookSubscription[] }>(`${this.apiUrl}/webhook/stores/${storeId}/webhooks`, this.options);
  }
} 