<div class="app-container">
  <!-- Header Section -->
  <div class="header-section">
    <div class="d-flex justify-content-between align-items-center mb-4">
        <div>
            <h2 class="text-primary mb-2 fw-bold">Webhook Subscriptions</h2>
        </div>
        <button class="btn btn-success" 
                (click)="openCreateModal()" 
                *ngIf="!areAllWebhooksCreated()">
            <i class="bi bi-plus-lg me-2"></i>
            Add Webhook
        </button>
    </div>
</div>

  <!-- Webhooks Card -->
  <div class="card">
    <div class="card-body p-0">
      <!-- Loading State -->
      <div *ngIf="isLoading" class="text-center p-5">
        <div class="spinner-border text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <!-- Empty State -->
      <div *ngIf="!isLoading && webhooks.length === 0" class="text-center p-5">
        <p class="mb-0">No webhooks configured yet. Click the button above to create one.</p>
      </div>

      <!-- Webhooks Table -->
      <div *ngIf="!isLoading && webhooks.length > 0" class="table-responsive">
        <table class="table table-hover mb-0">
          <thead>
            <tr>
              <th class="border-0">TOPIC</th>
              <th class="border-0">URL</th>
              <th class="border-0">STATE</th>
              <th class="border-0 text-end">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let webhook of webhooks">
              <td>
                <i class="bi bi-broadcast text-success me-2"></i>
                {{webhook.topic}}
              </td>
              <td class="text-muted">{{webhook.url}}</td>
              <td>
                <span class="badge rounded-pill" 
                      [class.bg-success]="webhook.state === 'ACTIVE'"
                      [class.bg-danger]="webhook.state === 'DISABLED'">
                  {{webhook.state}}
                </span>
              </td>
              <td class="text-end">
                <button class="btn btn-icon btn-light-primary btn-sm me-2" 
                        (click)="editWebhook(webhook)">
                  <i class="bi bi-pencil"></i>
                </button>
                <button class="btn btn-icon btn-sm me-2"
                        [class.btn-light-success]="webhook.state === 'ACTIVE'"
                        [class.btn-light-secondary]="webhook.state !== 'ACTIVE'"
                        (click)="toggleWebhook(webhook)"
                        [disabled]="isToggling === webhook.id">
                  <span *ngIf="isToggling === webhook.id" class="spinner-border spinner-border-sm"></span>
                  <i *ngIf="isToggling !== webhook.id" 
                     [class]="webhook.state === 'ACTIVE' ? 'bi bi-toggle-on' : 'bi bi-toggle-off'">
                  </i>
                </button>
                <button class="btn btn-icon btn-light-danger btn-sm" 
                        (click)="deleteWebhook(webhook)">
                  <i class="bi bi-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div> 