import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  NgbDatepickerModule,
  NgbModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ActivityDrawerComponent } from './components/activity-drawer/activity-drawer.component';
import { ChatInnerComponent } from './components/chat-inner/chat-inner.component';
import { CheckOptionsComponent } from './components/check-options/check-options.component';
import { DatetimePickerComponent } from './components/datetime-picker/datetime-picker.component';
import { DropdownInputComponent } from './components/dropdown-input/dropdown-input.component';
import { DropdownMenu1Component } from './components/dropdown-menu/dropdown-menu1/dropdown-menu1.component';
import { DropdownMenu2Component } from './components/dropdown-menu/dropdown-menu2/dropdown-menu2.component';
import { DropdownMenu3Component } from './components/dropdown-menu/dropdown-menu3/dropdown-menu3.component';
import { KeeniconComponent } from './components/keenicon/keenicon.component';
import { MessengerDrawerComponent } from './components/messenger-drawer/messenger-drawer.component';
import { ModalComponent } from './components/modal/modal.component';
import { MultiChoiceInputComponent } from './components/multi-choice-input/multi-choice-input.component';
import { MultiValueInputComponent } from './components/multi-value-input/multi-value-input.component';
import { SingleChoiceInputComponent } from './components/single-choice-input/single-choice-input.component';
import { CriteriaHostDirective } from './directives/criteria-host.directive';
import { PrettyJsonPipe } from './pipes/pretty-json.pipe';

@NgModule({
  declarations: [
    CheckOptionsComponent,
    DatetimePickerComponent,
    CriteriaHostDirective,
    DropdownInputComponent,
    MultiChoiceInputComponent,
    MultiValueInputComponent,
    SingleChoiceInputComponent,
    PrettyJsonPipe,
    KeeniconComponent,
    DropdownMenu1Component,
    DropdownMenu2Component,
    DropdownMenu3Component,
    ChatInnerComponent,
    MessengerDrawerComponent,
    ActivityDrawerComponent,
    ModalComponent,
  ],
  imports: [
    NgbModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    CommonModule,
    RouterModule,
    InlineSVGModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CheckOptionsComponent,
    DatetimePickerComponent,
    CriteriaHostDirective,
    DropdownInputComponent,
    MultiChoiceInputComponent,
    MultiValueInputComponent,
    SingleChoiceInputComponent,
    MessengerDrawerComponent,
    DropdownMenu1Component,
    PrettyJsonPipe,
    KeeniconComponent,
    ModalComponent,
  ],
})
export class SharedModule {}
