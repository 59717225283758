<ng-container *ngIf="appToolbarFilterButton">
  <!--begin::Filter menu-->
  <div class="m-0">
    <!--begin::Menu toggle-->
    <a href="#" class="btn btn-sm btn-flex fw-bold" [ngClass]="daterangepickerButtonClass" data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end">
      <app-keenicon name="gen031.svg" class="fs-6 svg-icon-muted me-1"></app-keenicon>
      Filter
    </a>
    <!--end::Menu toggle-->

    <app-dropdown-menu1></app-dropdown-menu1>
  </div>
  <!--end::Filter menu-->
</ng-container>

<ng-container *ngIf="appToolbarDaterangepickerButton">
  <div data-kt-daterangepicker="true" data-kt-daterangepicker-opens="left"
    class="btn btn-sm fw-bold  d-flex align-items-center px-4" [ngClass]="daterangepickerButtonClass">
    <!--begin::Display range-->
    <div class="text-gray-600 fw-bold">
      Loading date range...
    </div>
    <!--end::Display range-->
    <app-keenicon name="gen014.svg" class="fs-1 ms-2 me-0"></app-keenicon>
  </div>
</ng-container>

<!--begin::Secondary button-->
<!-- <a href="#" class="btn btn-sm btn-flex btn-light fw-bold">
  Filter
</a> -->
<!--end::Secondary button-->

<!--begin::Primary button-->
<!-- <a href="#" class="btn btn-sm fw-bold btn-primary" data-bs-toggle="modal"
  data-bs-target="#kt_modal_create_app">Create</a> -->
<!--end::Primary button-->
