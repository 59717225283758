<!--begin::Label-->
<span class="fs-7 fw-bold text-gray-700 pe-4 d-none d-md-block">Team:</span>
<!--end::Label-->

<!--begin::Users-->
<div class="symbol-group symbol-hover flex-shrink-0 me-2">
  <!--begin::User-->
  <div class="symbol symbol-circle symbol-35px">
    <div class="symbol-label fw-bold bg-warning text-inverse-warning">A</div>
  </div>
  <!--end::User-->

  <!--begin::User-->
  <div class="symbol symbol-circle symbol-35px">
    <img src="./assets/media/avatars/300-1.jpg" alt="" />
  </div>
  <!--end::User-->

  <!--begin::User-->
  <div class="symbol symbol-circle symbol-35px">
    <img src="./assets/media/avatars/300-2.jpg" alt="" />
  </div>
  <!--end::User-->

  <!--begin::User-->
  <div class="symbol symbol-circle symbol-35px">
    <div class="symbol-label fw-bold bg-primary text-inverse-primary">S</div>
  </div>
  <!--end::User-->

  <!--begin::User-->
  <div class="symbol symbol-circle symbol-35px">
    <img src="./assets/media/avatars/300-5.jpg" alt="" />
  </div>
  <!--end::User-->

  <!--begin::User-->
  <div class="symbol symbol-circle symbol-35px">
    <div class="symbol-label fw-bold bg-danger text-inverse-danger">P</div>
  </div>
  <!--end::User-->

  <!--begin::User-->
  <div class="symbol symbol-circle symbol-35px">
    <img src="./assets/media/avatars/300-20.jpg" alt="" />
  </div>
  <!--end::User-->
</div>
<!--end::Users-->

<!--begin::Button-->
<div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Invite a team member">
  <a href="#" class="btn btn-sm btn-icon">
    <app-keenicon name="gen035.svg" class="fs-2hx svg-icon-success"></app-keenicon>
  </a>
</div>
<!--end::Button-->
<!--end::Toolbar start-->

<!--begin::Toolbar end-->
<div class="d-flex align-items-center overflow-auto">
  <!--begin::Search-->
  <div class="position-relative my-1">
    <app-keenicon name="gen021.svg" class="fs-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10"></app-keenicon>
    <input type="text" class="form-control form-control-sm form-control-solid w-150px ps-10" name="Search Team" value=""
      placeholder="Search Team" />
  </div>
  <!--end::Search-->

  <!--begin::Separartor-->
  <div class="bullet bg-secondary h-35px w-1px mx-6"></div>
  <!--end::Separartor-->

  <!--begin::Label-->
  <span class="fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block">Sort By:</span>
  <!--end::Label-->

  <!--begin::Select-->
  <select class="form-select form-select-sm w-125px form-select-solid me-6" data-control="select2"
    data-placeholder="Latest" data-hide-search="true">
    <option value=""></option>
    <option value="1" selected>Latest</option>
    <option value="2">In Progress</option>
    <option value="3">Done</option>
  </select>
  <!--end::Select-->

  <!--begin::Actions-->
  <div class="d-flex align-items-center">
    <button type="button" class="btn btn-sm btn-icon btn-light-primary me-3" data-bs-toggle="tooltip"
      data-bs-placement="top" title="Enable grid view">
      <app-keenicon name="gen025.svg" class="fs-3 text-primary"></app-keenicon>
    </button>

    <button type="button" class="btn btn-sm btn-icon btn-light" data-bs-toggle="tooltip" data-bs-placement="top"
      title="Enable row view">
      <app-keenicon name="gen010.svg" class="fs-3 svg-icon-gray-400"></app-keenicon>
    </button>
  </div>
  <!--end::Actions-->
