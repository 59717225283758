import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Client } from '../../../../shared/models/client.model';

@Component({
  selector: 'app-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss'],
})
export class ClientCardComponent {
  @Input() client: Client;
  @Output() selected: EventEmitter<Client> = new EventEmitter<Client>();

  onClientSelected(): void {
    this.selected.emit(this.client);
  }
}
