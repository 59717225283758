import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Client } from '../../../../shared/models/client.model';
import { Store } from '../../../../shared/models/store.model';
import { TenantService } from '../../../tenants/services/tenants.service';
import { ClientService } from '../../services/client.service';
import { NewClientComponent } from '../new-client/new-client.component';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss'],
})
export class ClientsListComponent implements OnInit {
  public isNewClient: boolean = false;
  public isNewStore: boolean = false;
  public isStoreCompleted: boolean = false;
  public goToClient: boolean = false;
  public client: Client = new Client();
  public store: Store = new Store();
  public linkAuth: string;
  public clients: Client[] = [];
  activeTab: string;

  constructor(
    private clientService: ClientService,
    private tenantService: TenantService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {
     this.activeTab = localStorage.getItem('activeTab') || 'clients';
  }

  ngOnInit(): void {
    this.spinner.show('listClients');
    this.fetchTenantClients();
  }

  private fetchTenantClients() {
    this.tenantService.getTenantClients('atomix').subscribe({
      next: (clients) => {
        this.clients = clients;
        this.spinner.hide('listClients');
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.spinner.hide('listClients');
      },
    });
  }

  public selectClient(client: Client) {
    this.clientService.setCurrentClient(client);
    this.router.navigate(['/client/stores']);
  }

  public async createClient() {
    this.isNewClient = true;
    // return await this.modalFormClient.open();
    this.openModal();
  }

  openModal() {
    // Define modal options
    const modalConfig: NgbModalOptions = {
      size: 'lg', // large size
      backdrop: 'static', // modal should not close when clicking outside
      keyboard: false, // disable closing modal by pressing ESC
      centered: true, // center the modal vertically in the screen
      animation: true, // enable animations
    };

    const modalRef = this.modalService.open(NewClientComponent, modalConfig);
    modalRef.componentInstance.clients = this.clients;

    // Listen to the 'outContinue' event
    modalRef.componentInstance.clientCreated.subscribe((data) => {
      this.fetchTenantClients();
    });

    modalRef.result.then(
      (result) => {},
      (reason) => {}
    );
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
     localStorage.setItem('activeTab', tab);
  }
}
