import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from '../../../stores/services/store.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-shopify-auth-verify',
  templateUrl: './shopify-auth-verify.component.html',
  styleUrls: ['./shopify-auth-verify.component.scss'],
})
export class ShopifyAuthVerifyComponent implements OnInit {
  itemClass: string = 'ms-1 ms-lg-3';
  btnClass: string =
    'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
  userAvatarClass: string = 'symbol-35px symbol-md-40px';
  btnIconClass: string = 'fs-2 fs-md-1';

  private code: string;
  private hmac: string;
  private host: string;
  private shop: string;
  private state: string;
  private timestamp: string;
  public verifiedStatus: string = 'pending';
  public title: string = 'Store verification';
  public description: string =
    'Click Finish to confirm the integration of your store';
  public textButton: string = 'Finish';
  public src: string = '../../../assets/media/illustrations/sigma-1/17.png';

  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private storeService: StoreService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      const { code, hmac, host, shop, state, timestamp } = params;
      this.code = code;
      this.hmac = hmac;
      this.host = host;
      this.shop = shop;
      this.state = state;
      this.timestamp = timestamp;
    });

    this.verify();
  }

  public verify() {
    let body = {
      code: this.code,
      hmac: this.hmac,
      host: this.host,
      shop: this.shop,
      state: this.state,
      timestamp: this.timestamp,
    };
    try {
      this.authService.shopifyAuthVerify(body).subscribe((store) => {
        this.storeService.notifyStore(store);
        this.title = 'Verified Store';
        this.description =
          'Your store was successfully verified, you can now close this window.';
        this.verifiedStatus = 'verified';
        this.src = '../../../assets/media/illustrations/sigma-1/8.png';
        this.cdr.detectChanges();
      });
    } catch (error) {
      this.verifiedStatus = 'error';
      this.title = 'Oops sorry, verification failed ';
      this.description =
        'Could not verify the store, the redirect expired or its link was cancelled, contact the administrator to acquire the new link.';
      this.verifiedStatus = 'error';
      this.src = '../../../assets/media/illustrations/sigma-1/21.png';
    }
  }

  public close() {
    window.close();
  }
}
