import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-multi-choice-input',
  templateUrl: './multi-choice-input.component.html',
  styleUrls: ['./multi-choice-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiChoiceInputComponent),
      multi: true,
    },
  ],
})
export class MultiChoiceInputComponent implements OnInit, ControlValueAccessor {
  @Input() options: { value: string; displayValue: string }[] = [];
  @Input() selectedOptions: string[] = [];
  @Input() readOnly: boolean = false;
  @Output() change = new EventEmitter<string[]>(); // Emitting an array of strings

  onChange: (value: string[]) => void = () => {};
  onTouched: () => void = () => {};
  formControl: FormControl<any>;

  public displayOptions: {
    value: string;
    displayValue: string;
    checked: boolean;
  }[] = [];

  ngOnInit(): void {
    this.initializeDisplayOptions();
  }

  setControl(control: FormControl) {
    this.formControl = control;
    this.formControl.valueChanges.subscribe((value) => {
      // Handle value changes if needed
      this.writeValue(value);
    });

    // Also, bind the ControlValueAccessor functions to update the form control
    this.registerOnChange((value: string[]) =>
      this.formControl.setValue(value)
    );
    this.registerOnTouched(() => this.formControl.markAsTouched());
  }

  private initializeDisplayOptions() {
    this.displayOptions = this.options.map((option) => ({
      ...option,
      checked: this.selectedOptions.includes(option.value),
    }));
  }

  listenForEdit(editControl: FormControl) {
    editControl.valueChanges.subscribe((value: boolean) => {
      this.readOnly = !value;
    });
  }

  writeValue(selectedOptions: string[]): void {
    this.selectedOptions = selectedOptions || [];
    this.initializeDisplayOptions(); // Ensure UI reflects model state
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.readOnly = isDisabled;
  }

  toggleOption(option: {
    value: string;
    displayValue: string;
    checked: boolean;
  }) {
    if (this.readOnly) {
      return; // Do nothing if the component is in readOnly mode
    }

    option.checked = !option.checked; // Toggle the checked state

    // Update the selectedOptions based on the new checked state
    if (option.checked) {
      this.selectedOptions.push(option.value);
    } else {
      this.selectedOptions = this.selectedOptions.filter(
        (value) => value !== option.value
      );
    }

    this.formControl.setValue(this.selectedOptions);
    this.onChange(this.selectedOptions);
    this.change.emit(this.selectedOptions);
  }

  // This method might not be necessary anymore since we update displayOptions directly in toggleOption
  isOptionSelected(option: { value: string; displayValue: string }): boolean {
    return this.selectedOptions.includes(option.value);
  }
}
