import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Client } from '../../../shared/models/client.model';
import {
  CornerstoneApiResponse,
  HttpOptions,
} from '../../../shared/models/http.model';
import { HttpService } from '../../../shared/services/http.service';

const NEST_API_URL = environment.nestApiUrl;

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  private httpOptions: HttpOptions;

  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient
  ) {
    this.httpOptions = this.httpService.getHttpOptions();
  }

  public getTenantClients(tenantId: string): Observable<Client[]> {
    //TODO: Replace URL `${environment.apiUrl}/clients/${tenantId}` with `${NEST_API_URL}/tenants/${tenantId}/clients` URL when its added on backend.
    return this.httpClient
      .get<CornerstoneApiResponse<Client[]>>(
        `${environment.apiUrl}/clients/${tenantId}`,
        this.httpOptions
      )
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          throw error;
        })
      );
  }
}
