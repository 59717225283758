<!-- app-dropdown.component.html -->
<select
  [class.is-invalid]="!isValid && isTouched"
  [disabled]="readOnly"
  [ngModel]="value"
  (ngModelChange)="selectOption($event)"
  (blur)="onTouch()"
>
  <option *ngFor="let key of objectKeys(options)" [value]="key">
    {{ options[key] }}
  </option>
</select>
