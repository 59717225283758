export class Store {
  uuid: string;
  name: string;
  url: string;
  type: 'SHOPIFY' | 'WOOCOMMERCE' | 'WALMART' | 'ORDERFUL'; // Will be more to add;
  clientId: string;
  sourceId: string;
  state: 'ACTIVE' | 'PENDING' | 'DISABLED';
  lastOrderSync: Date;
  lastShipmentSync: Date;
  lastInventorySync: Date;

  
  createdAt: Date;
  updatedAt: Date;
  config: StoreConfig;
  auth: StoreAuth;

  public setStore(_store: any) {
    Object.assign(this, _store);
  }
}

export interface StoreConfig {
  connection?: boolean;
  orderSync?: OrderSyncConfig;
  shipmentSync?: ShipmentSyncConfig;
  inventorySync?: InventorySyncConfig;
  orderFilterEnabled?: boolean;
  itemFilterEnabled?: boolean;
  walmartKeys?:WalmartKeys
  username?: string;
  password?: string;
}


export interface FulfillmentServiceConfig {
  locationId: string;
  enabled: boolean;
  name: string;
}
export interface OrderSyncConfig {
  enabled: boolean;
  syncStartDate?: Date;
  transactionType?: string;
  orderStatus?: string;
  customStatus?: string;
  isConsolidated?: boolean;
  fulfillmentService?: FulfillmentServiceConfig;
  filterByLocation?: {
    atomixLocationId?: string;
    enabled: boolean;
  };
}

export interface ShipmentSyncConfig {
  enabled: boolean;
  shipmentType?: string;
}

export interface InventorySyncConfig {
  enabled: boolean;
  locationId?: string;
  inventoryType?: string;
}

export interface StoreAuth {
  state: string;
  access_token: string;
  authorization_code: string;
  scope: string;
}

export interface StoreCreateRequest {
  name: string;
  type: string;
  url: string;
  clientId: string;
  sourceId?: string;

  config: StoreConfig;

  incomingTransactionType?: string;
  outgoingShipmentType?: string;
  inventorySyncType?: string;
   
}

export interface WalmartKeys {
  clientId: string;
  clientSecret?: string;
  supplierId:string
}