export class AuthToken {
  accessToken: string;
  refreshToken: string;
  expirationTime: number;
  expiresIn: Date;

  setAuth(_authToken: unknown) {
    const authToken = _authToken as AuthToken;
    this.accessToken = authToken.accessToken;
    this.refreshToken = authToken.refreshToken;
    this.expirationTime = authToken.expirationTime;
    this.expiresIn = new Date(authToken.expirationTime);
  }
}
