import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthToken } from '../models/auth.model';
import { User } from '../models/user.model';
import { ErrorHandlerService } from './error-handler.service';
const API_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private authLocalStorageToken = `authToken`;
  private apiUrl = `${environment.apiUrl}`;

  constructor(
    private httpClient: HttpClient,
    private errorHandler: ErrorHandlerService
  ) {}

  private getHttpHeaders(): HttpHeaders {
    const token = this.getAuthTokenFromLocalStorage();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    });

    if (token) {
      headers = headers.set('Authorization', `Bearer ${token.accessToken}`);
    }

    return headers;
  }

  public getHttpOptions() {
    return {
      headers: this.getHttpHeaders(),
      observe: 'body' as 'body', // Ensure body is observed
      responseType: 'json' as 'json', // Ensure JSON is the default response type
    };
  }

  public getUserByToken(token: string): Observable<any> {
    return this.httpClient
      .post(
        `${this.apiUrl}/verify-token`,
        { token },
        { headers: this.getHttpHeaders() }
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  public login(email: string, token: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpClient
        .post<User>(
          `${this.apiUrl}/login`,
          { email, token },
          { headers: this.getHttpHeaders() }
        )
        .pipe(retry(3), catchError(this.handleError))
        .subscribe({
          next: (res) => resolve(res),
          error: (err) => reject(err),
        });
    });
  }

  private getAuthTokenFromLocalStorage(): AuthToken | null {
    try {
      const authTokenData = localStorage.getItem(this.authLocalStorageToken);
      return authTokenData ? JSON.parse(authTokenData) : null;
    } catch (error) {
      console.error('Error reading from localStorage', error);
      return null;
    }
  }

  private handleError(error: HttpErrorResponse) {
    console.error('Backend returned error: ', error);
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }
}
