<form
  class="form w-100"
  [formGroup]="loginForm"
  novalidate="novalidate"
  id="kt_login_signin_form"
  (ngSubmit)="onSubmit()"
>
  <div class="text-center mb-11">
    <h1 class="fw-bolder mb-3 text-white">Sign In</h1>
  </div>
  <ng-container *ngIf="hasError">
    <div class="mb-lg-15 alert alert-danger">
      <div class="alert-text font-weight-bold">
        {{ messageError }}
      </div>
    </div>
  </ng-container>
  <div class="fv-row mb-8">
    <label class="form-label fs-6 fw-bolder text-white" for="email"
      >Email</label
    >
    <input
      class="form-control bg-transparent"
      type="email"
      id="email"
      name="email"
      formControlName="email"
      autocomplete="off"
      [ngClass]="{
        'is-invalid': loginForm.controls['email'].invalid,
        'is-valid': loginForm.controls['email'].valid
      }"
    />
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Email is required',
        control: loginForm.controls['email']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'email',
        message: 'Email is invalid',
        control: loginForm.controls['email']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'minLength',
        message: 'Email should have at least 3 symbols',
        control: loginForm.controls['email']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Email should have maximum 360 symbols',
        control: loginForm.controls['email']
      }"
    ></ng-container>
  </div>
  <div class="fv-row mb-3">
    <label class="form-label fw-bolder text-white fs-6 mb-0" for="password"
      >Password</label
    >
    <input
      class="form-control bg-transparent"
      type="password"
      id="password"
      name="password"
      autocomplete="off"
      formControlName="password"
      [ngClass]="{
        'is-invalid': loginForm.controls['password'].invalid,
        'is-valid': loginForm.controls['password'].valid
      }"
    />
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Password is required',
        control: loginForm.controls['password']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'minlength',
        message: 'Password should have at least 3 symbols',
        control: loginForm.controls['password']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Password should have maximum 100 symbols',
        control: loginForm.controls['password']
      }"
    ></ng-container>
  </div>
  <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
    <div></div>
    <span id="forgot-password" (click)="onForgotPassword()">
      Forgot Password ?
    </span>
  </div>
  <div class="d-grid mb-10">
    <button
      type="submit"
      id="kt_sign_in_submit"
      class="btn btn-primary"
      [disabled]="loginForm.invalid"
    >
      <ng-container *ngIf="isLoading">
        <span class="indicator-progress" [style.display]="'block'">
          Please wait...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <span class="indicator-label">Sign In</span>
      </ng-container>
    </button>
  </div>
</form>

<ng-template
  #formError
  let-control="control"
  let-message="message"
  let-validation="validation"
>
  <ng-container
    *ngIf="control.hasError(validation) && (control.dirty || control.touched)"
  >
    <div class="fv-plugins-message-container">
      <span role="alert">
        {{ messageError }}
      </span>
    </div>
  </ng-container>
</ng-template>
