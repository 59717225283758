<!--begin::Input group-->
<div class="d-flex align-items-center flex-shrink-0">
  <!--begin::Label-->
  <span class="fs-7 text-gray-700 fw-bold pe-3 d-none d-md-block">Actions:</span>
  <!--end::Label-->

  <!--begin::Actions-->
  <div class="d-flex flex-shrink-0">
    <!--begin::Button-->
    <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Add a team member">
      <a href="#" class="btn btn-sm btn-icon btn-active-color-success">
        <app-keenicon name="gen035.svg" class="fs-2x"></app-keenicon>
      </a>
    </div>
    <!--end::Button-->

    <!--begin::Button-->
    <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Create new account">
      <a href="#" class="btn btn-sm btn-icon btn-active-color-success">
        <app-keenicon name="gen037.svg" class="fs-2x"></app-keenicon>
      </a>
    </div>
    <!--end::Button-->

    <!--begin::Button-->
    <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Invite friends">
      <a href="#" class="btn btn-sm btn-icon btn-active-color-success">
        <app-keenicon name="gen023.svg" class="fs-2x"></app-keenicon>
      </a>
    </div>
    <!--end::Button-->
  </div>
  <!--end::Actions-->
</div>
<!--end::Input group-->

<!--begin::Input group-->
<div class="d-flex align-items-center flex-shrink-0">
  <!--begin::Desktop separartor-->
  <div class="bullet bg-secondary h-35px w-1px mx-5"></div>
  <!--end::Desktop separartor-->

  <!--begin::Label-->
  <span class="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block">Progress:</span>
  <!--end::Label-->

  <div class="progress w-100px w-xl-150px w-xxl-300px h-25px bg-light-success">
    <div class="progress-bar rounded bg-success fs-7 fw-bold" role="progressbar" style="width: 72%;" aria-valuenow="72"
      aria-valuemin="0" aria-valuemax="100">72%</div>
  </div>
</div>
<!--end::Input group-->
<!--end::Toolbar start-->

<!--begin::Toolbar end-->
<div class="d-flex align-items-center">
  <!--begin::Input group-->
  <div class="me-3">
    <!--begin::Select-->
    <select class="form-select form-select-sm form-select-solid" data-control="select2" data-placeholder="Latest"
      data-hide-search="true">
      <option value=""></option>
      <option value="1" selected>Today 16 Feb</option>
      <option value="2">In Progress</option>
      <option value="3">Done</option>
    </select>
    <!--end::Select-->
  </div>
  <!--end::Input group--->

  <!--begin::Input group--->
  <div class="m-0">
    <!--begin::Select-->
    <select class="form-select form-select-sm form-select-solid w-md-125px" data-control="select2"
      data-placeholder="Filters" data-hide-search="true">
      <option value=""></option>
      <option value="1" selected>Filters</option>
      <option value="2">In Progress</option>
      <option value="3">Done</option>
    </select>
    <!--end::Content-->
  </div>
  <!--end::Input group--->
</div>
