// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IndividualConfig } from 'ngx-toastr';

export const environment = {
  firebase: {
    apiKey: 'AIzaSyAlJONYdz7UgrIxEUjhkqrv8mK4-TBsuCE',
    authDomain: 'dev-cornerstone.firebaseapp.com',
    projectId: 'dev-cornerstone',
    storageBucket: 'dev-cornerstone.appspot.com',
    messagingSenderId: '211615153026',
    appId: '1:211615153026:web:0677bd96c0b35c4711ad9b',
    measurementId: 'G-L5HZJX09PR',
  },
  useEmulators: false,
  authEmulatorUrl: '',
  production: false,
  appVersion: 'v1.0.0',
  appName: 'cornerstone',
  USERDATA_KEY: 'authf649fc9a5f55',
  apiUrl: 'https://dev-cornerstone-api.atomix.tech',
  nestApiUrl: 'https://dev-cornerstone-api-nestjs.atomix.tech',
  socketUrl: 'https://dev-cornerstone-api.atomix.tech',
  appThemeName: 'Atomix',
  currentClientKey: 'currentClient',
  isMockEnabled: true,
  appPurchaseUrl: 'https://1.envato.market/EA4JP',
  appPreviewUrl: 'https://preview.keenthemes.com/metronic8/angular/demo1/',
  appPreviewDocsUrl: 'https://preview.keenthemes.com/metronic8/angular/docs',
  appPreviewChangelogUrl:
    'https://preview.keenthemes.com/metronic8/angular/docs/changelog',
};
//
export const optionsToast: Partial<IndividualConfig<any>> = {
  timeOut: 2000,
  enableHtml: true,
  progressBar: true,
  progressAnimation: 'increasing',
  closeButton: true,
  positionClass: 'toast-top-right',
  tapToDismiss: false,
};
