<ng-container *ngIf="appContentContainer">
  <!--begin::Content container-->
  <div
    id="kt_app_content_container"
    class="app-container"
    [ngClass]="appContentContainerClass + ' ' + contentContainerCSSClass"
    [ngClass]="{
      'container-xxl': appContentContainer === 'fixed',
      'container-fluid': appContentContainer === 'fluid'
    }"
  >
    <router-outlet></router-outlet>
  </div>
  <!--end::Content container-->
</ng-container>
<ng-container *ngIf="!appContentContainer">
  <router-outlet></router-outlet>
</ng-container>
