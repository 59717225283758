<div *ngIf="show">
  <h6>{{ title }}</h6>
  <div *ngIf="type === 'single'; else multipleOptions">
    <fieldset [disabled]="disabled">
      <ng-container *ngFor="let option of displayOptions">
        <div class="form-check-inline border rounded p-2 mb-2">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              [name]="title"
              [id]="title + '_' + option.value"
              [value]="option.value"
              [checked]="option.checked"
              (change)="changeValue($event)"
            />
            <label
              class="form-check-label"
              [for]="title + '_' + option.value"
              >{{ option.displayValue }}</label
            >
          </div>
        </div>
      </ng-container>
    </fieldset>
  </div>
  <ng-template #multipleOptions>
    <ng-container *ngFor="let option of displayOptions">
      <div class="form-check-inline border rounded p-2 mb-2">
        <div class="form-check">
          <input
            class="form-check-input"
            [disabled]="disabled"
            type="checkbox"
            [name]="title"
            [id]="title + '_' + option.value"
            [value]="option.value"
            [checked]="option.checked"
            (change)="changeValue($event)"
          />
          <label class="form-check-label" [for]="title + '_' + option.value">{{
            option.displayValue
          }}</label>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
