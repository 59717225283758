<div class="container-start">
  <ngx-spinner
    [name]="'listClients'"
    bdColor="rgba(255,255,255,1)"
    size="medium"
    color="#82FC62"
    type="ball-pulse"
    [fullScreen]="true"
  ></ngx-spinner>
  <div class="header-client d-flex justify-content-between">
    <div>
      <img
        class="align-self-center"
        src="../../../../assets/media/logos/logo-black.png"
        width="150px"
        alt=""
      />
    </div>
    <app-logout-button></app-logout-button>
  </div>
<!--  TODO: Add <router-outlet></router-outlet> to loaded dynamic modules -->
  <app-clients-list></app-clients-list>
</div>
