<div *ngIf="title">
  <h6>
    {{ title }}
  </h6>
</div>
<div
  class="multi-value-container p-2"
  [class.is-invalid]="(!isValid && isTouched) || showError"
>
  <div class="d-flex flex-wrap gap-1 align-items-center badge-container">
    <div class="badge bg-primary" *ngFor="let value of values; let i = index">
      {{ value }}
      <button
        type="button"
        class="btn-close btn-close-white ms-2"
        aria-label="Remove"
        (click)="removeValue(i)"
        [disabled]="readOnly"
      ></button>
    </div>
    <input
      *ngIf="!readOnly"
      [(ngModel)]="inputValue"
      (keydown)="onKeyDown($event)"
      (blur)="onBlur($event)"
      placeholder="Type and press Enter"
      [disabled]="readOnly"
      class="form-control badge-input"
    />
  </div>
</div>
<div *ngIf="showError" class="text-danger">
  Input string cannot be empty. Type and press Enter
</div>
