<form
  class="form w-100"
  novalidate="novalidate"
  [formGroup]="forgotPasswordForm"
  (ngSubmit)="submit()"
  id="kt_login_password_reset_form"
>
  <!--begin::Heading-->
  <div class="text-center mb-10">
    <!--begin::Title-->
    <h1 class="text-dark fw-bolder mb-3">Forgot Password ?</h1>
    <!--end::Title-->

    <!--begin::Link-->
    <div class="text-gray-500 fw-semibold fs-6">
      Enter your email to reset your password.
    </div>
    <!--end::Link-->
  </div>
  <!--begin::Heading-->

  <!-- begin::Alert error-->
  <ng-container *ngIf="errorState === errorStates.HasError">
    <div class="mb-lg-15 alert alert-danger">
      <div class="alert-text font-weight-bold">
        Sorry, looks like there are some errors detected, please try again.
      </div>
    </div>
  </ng-container>
  <!-- end::Alert error-->

  <ng-container *ngIf="errorState === errorStates.NoError">
    <div class="mb-10 bg-light-info p-8 rounded">
      <!--begin::Body-->
      <div class="text-info">
        Success! If your email is registered to an account with us, please check
        your inbox for a password reset link.
      </div>
      <!--end::Body-->
    </div>
  </ng-container>

  <!--begin::Form group-->
  <div class="fv-row mb-8">
    <label class="form-label fw-bolder text-gray-900 fs-6">Email</label>
    <input
      class="form-control bg-transparent"
      type="email"
      formControlName="email"
      placeholder="admin@email.com"
      name="email"
      autocomplete="off"
      [ngClass]="{
        'is-invalid': forgotPasswordForm.controls['email'].invalid,
        'is-valid': forgotPasswordForm.controls['email'].valid
      }"
    />

    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Email is required',
        control: forgotPasswordForm.controls['email']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'email',
        message: 'Email is invalid',
        control: forgotPasswordForm.controls['email']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'minLength',
        message: 'Email should have at least 3 symbols',
        control: forgotPasswordForm.controls['email']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Email should have maximum 360 symbols',
        control: forgotPasswordForm.controls['email']
      }"
    ></ng-container>
  </div>
  <!--end::Form group-->
  <!--begin::Form group-->
  <div class="d-flex flex-wrap justify-content-center pb-lg-0">
    <button
      type="submit"
      id="kt_password_reset_submit"
      class="btn btn-primary me-4"
    >
      <span class="indicator-label">Submit</span>
    </button>
    <a
      routerLink="/auth/login"
      id="kt_login_password_reset_form_cancel_button"
      class="btn btn-light"
    >
      Back
    </a>
  </div>
  <!--end::Form group-->
</form>
<!--end::Form-->

<ng-template
  #formError
  let-control="control"
  let-message="message"
  let-validation="validation"
>
  <ng-container
    *ngIf="control.hasError(validation) && (control.dirty || control.touched)"
  >
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>
