<div class="menu-item me-lg-1" *ngIf="currentClient && isClientRoute()">
  <div class="col">
    <div ngbDropdown class="d-inline-block">
      <a
        type="button"
        class="btn btn-outline-primary menu-link py-3"
        routerLinkActive="active menu-here"
        id="clientDropdoen"
        ngbDropdownToggle
      >
        {{ currentClient.name }}
      </a>
      <div ngbDropdownMenu aria-labelledby="clientDropdoen">
        <button ngbDropdownItem class="text-success">
          {{ currentClient.name }}
        </button>
        <hr class="dropdown-divider" />
        <button
          ngbDropdownItem
          *ngFor="let client of clients"
          (click)="selectClient(client)"
        >
          {{ client.name }}
        </button>
        <hr class="dropdown-divider" />
        <button ngbDropdownItem class="text-primary" (click)="addClient()">
          Add client
        </button>
      </div>
    </div>
  </div>
</div>
<div class="menu-item me-lg-1" *ngIf="!isClientRoute()">
  <a
    class="menu-link py-3 btn btn-outline-secondary"
    routerLink="/client/stores"
    routerLinkActive="active"
  >
    <span class="menu-title">
      <app-keenicon name="shop" class="fs-2"></app-keenicon>
    </span>
  </a>
</div>
