<!--begin::Header container-->
<div
  class="app-container"
  id="kt_app_header_container"
  [ngClass]="headerContainerCssClass"
>
  <ng-container *ngIf="appSidebarDefaultCollapseDesktopEnabled">
    <div class="app-sidebar-collapse-d-flex align-items-center me-3">
      <!--begin::sidebar toggle-->
      <div
        class="btn btn-icon w-auto px-0 btn-color-muted btn-active-icon-primary"
        data-kt-toggle="true"
        data-kt-toggle-target="body"
        data-kt-toggle-mode="on"
        data-kt-toggle-name="app-sidebar-collapse"
      >
        <app-keenicon name="double-right" class="fs-1"></app-keenicon>
      </div>
      <!--end::sidebar toggle-->
    </div>
  </ng-container>

  <ng-container *ngIf="appSidebarDisplay">
    <!--begin::sidebar mobile toggle-->
    <div
      class="d-flex align-items-center d-lg-none ms-n2 me-2"
      title="Show sidebar menu"
    >
      <div
        class="btn btn-icon btn-active-color-primary w-35px h-35px"
        id="kt_app_sidebar_mobile_toggle"
      >
        <app-keenicon name="abstract-14" class="fs-1"></app-keenicon>
      </div>
    </div>
    <!--end::sidebar mobile toggle-->
    <!--begin::Mobile logo-->
    <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
      <a routerLink="/client/stores" class="d-lg-none">
        <img alt="Logo" src="./assets/media/logos/logo.png" class="h-30px" />
      </a>
    </div>
    <!--end::Mobile logo-->
  </ng-container>

  <ng-container *ngIf="!appSidebarDisplay">
    <!--begin::Logo-->
    <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
      <a routerLink="/client/stores">
        <ng-container *ngIf="currentLayoutType === 'dark-header'">
          <img
            alt="Logo"
            src="./assets/media/logos/logo.png"
            class="h-20px h-lg-30px app-sidebar-logo-default"
          />
        </ng-container>
        <ng-container *ngIf="currentLayoutType !== 'dark-header'">
          <img
            alt="Logo"
            src="./assets/media/logos/logo-black.png"
            class="h-20px h-lg-30px app-sidebar-logo-default theme-light-show"
          />
          <img
            alt="Logo"
            src="./assets/media/logos/logo.png"
            class="h-20px h-lg-30px app-sidebar-logo-default theme-dark-show"
          />
        </ng-container>
      </a>
    </div>
    <!--end::Logo-->
  </ng-container>

  <!--begin::Header wrapper-->
  <div
    class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
    id="kt_app_header_wrapper"
  >
    <ng-container
      *ngIf="appHeaderDefaultContent === 'menu' && appHeaderDefaulMenuDisplay"
    >
      <!--begin::Menu wrapper-->
      <div
        class="app-header-menu app-header-mobile-drawer align-items-stretch"
        data-kt-drawer="true"
        data-kt-drawer-name="app-header-menu"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="225px"
        data-kt-drawer-direction="end"
        data-kt-drawer-toggle="#kt_app_header_menu_toggle"
        data-kt-swapper="true"
        data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
        data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
      >
        <!--begin::Menu-->
        <app-header-menu
          class="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
          id="kt_app_header_menu"
          data-kt-menu="true"
        ></app-header-menu>
        <!--end::Menu-->
      </div>
      <!--end::Menu wrapper-->
    </ng-container>
    <app-logout-button></app-logout-button>
  </div>
  <!--end::Header wrapper-->
</div>
<!--end::Header container-->
