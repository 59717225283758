import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { LoggerService } from '../../shared/services/logger.service';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(private logger: LoggerService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const timeoutValue = 600000; // 10 minutes timeout
    return next.handle(req).pipe(
      timeout(timeoutValue), // Apply the timeout
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          this.logger.error(
            `Timeout Error: Request to ${req.url} timed out after ${timeoutValue} ms`
          );
          return throwError(
            () => new Error('Request timed out! Please try again later.')
          );
        } else {
          this.logger.error(
            `HTTP Error for ${req.url}:\n ${JSON.stringify(error)}`
          );
          return throwError(() => error);
        }
      })
    );
  }
}
