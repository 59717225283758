import { NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD HH:mm',
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true,
    },
  ],
})
export class DatetimePickerComponent
  implements ControlValueAccessor, OnInit, OnDestroy, OnChanges
{
  @Input() dateConfig: any = {
    minDate: undefined,
    maxDate: undefined,
    disabledDates: [],
    dateFormat: 'dd-MM-yyyy',
  };

  @Input() timeConfig: any = {
    timeFormat: 'HH:mm',
    minuteStep: 1,
  };

  @Input() startDateTime: Date | string; // Accepts start datetime

  form: FormGroup;
  private destroy$ = new Subject<void>();

  // Explicitly define FormControl properties for template access
  get dateControl(): FormControl {
    return this.form.get('date') as FormControl;
  }

  get timeControl(): FormControl {
    return this.form.get('time') as FormControl;
  }

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.form = this.fb.group({
      date: new FormControl(),
      time: new FormControl(),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startDateTime && changes.startDateTime.currentValue) {
      this.initializeDateTime(changes.startDateTime.currentValue);
    }
  }

  ngOnInit(): void {
    this.form
      .get('date')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.combineDateTimeAndEmit();
      });

    this.form
      .get('time')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.combineDateTimeAndEmit();
      });
  }

  private combineDateTimeAndEmit(): void {
    const dateParts = this.form.get('date').value; // Get date value safely
    const timeParts = this.form.get('time').value; // Get time value safely

    // Check if both dateParts and timeParts are not null
    if (
      dateParts &&
      timeParts &&
      dateParts.year &&
      timeParts.hour !== undefined
    ) {
      // Create a new Date object combining date and time parts
      const combinedDateTime = new Date(
        dateParts.year,
        dateParts.month - 1,
        dateParts.day,
        timeParts.hour,
        timeParts.minute
      );

      // Emit the combined datetime to the form control using the registered onChange function
      this.onChange(combinedDateTime.toISOString()); // or this.onChange(combinedDateTime) if Date object is expected
    } else {
    }
  }

  private initializeDateTime(startDateTime: Date | string) {
    const dateTime =
      typeof startDateTime === 'string'
        ? new Date(startDateTime)
        : startDateTime;
    const dateValue = {
      year: dateTime.getFullYear(),
      month: dateTime.getMonth() + 1,
      day: dateTime.getDate(),
    };
    const timeValue = {
      hour: dateTime.getHours(),
      minute: dateTime.getMinutes(),
    };

    this.form.setValue(
      {
        date: dateValue,
        time: timeValue,
      },
      { emitEvent: false }
    );

    this.cdr.detectChanges(); // Force change detection
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(val: Date | string): void {
    if (val != null) {
      this.initializeDateTime(val);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      if (this.form.disabled) {
        this.form.enable();
      }
    }
  }

  isDisabled = (date: NgbDateStruct, { year, month }) => {
    return this.dateConfig.disabledDates.some(
      (disabledDate: { year: number; month: number; day: number }) =>
        disabledDate.year === date.year &&
        disabledDate.month === date.month &&
        disabledDate.day === date.day
    );
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
