import { Subscription } from "rxjs";

export class SubscriptionWrappers {
  private _subscriptions: Subscription[] = [];

  public add(subscription: Subscription) {
      this._subscriptions.push(subscription);
  }

  public cleanup() {
      this._subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
