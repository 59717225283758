import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-single-choice-input',
  templateUrl: './single-choice-input.component.html',
  styleUrls: ['./single-choice-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleChoiceInputComponent),
      multi: true,
    },
  ],
})
export class SingleChoiceInputComponent implements ControlValueAccessor {
  @Input() options: Array<{ value: string; displayValue: string }>;
  @Input() selected: string[];
  @Input() readOnly: boolean = false;
  @Output() change = new EventEmitter<{
    value: string;
    displayValue: string;
  }>();
  onChange: any = () => {};
  onTouch: any = () => {};
  formControl: FormControl;

  setControl(control: FormControl) {
    this.formControl = control;
    this.formControl.valueChanges.subscribe((value: string[]) => {
      // Handle value changes if needed
      this.writeValue(value);
    });

    // Also, bind the ControlValueAccessor functions to update the form control
    this.registerOnChange((value: string[]) =>
      this.formControl.setValue(value)
    );
    this.registerOnTouched(() => this.formControl.markAsTouched());
  }

  selectOption(option: { value: string; displayValue: string }) {
    this.change.emit(option);
  }

  writeValue(values: string[]): void {
    if (values) {
      this.selected = values;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
