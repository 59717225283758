<div *ngIf="success" class="ok-screen">
  <img src="../../../../assets/media/stores/ok.png" alt="Success" />
  <p>Client Creation Successful!</p>
</div>

<ngx-spinner name="createClient" bdColor="rgba(0,0,0,0.8)" size="medium" color="#fff" type="ball-scale-multiple"
  [fullScreen]="true">
  <p style="color: white">Loading...</p>
</ngx-spinner>

<div class="app-new-client" *ngIf="!success">
  <form (ngSubmit)="submitForm()" class="form">
    <div class="modal-header">
      <h4 class="modal-title">Add New Client</h4>
      <button type="button" class="close-button" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-6 form-group">
          <label for="clientName">Client Name*</label>
          <input type="text" id="clientName" [formControl]="clientName" class="form-control" placeholder="Client Name*"
            [ngClass]="{
              'is-invalid':
                clientName.touched &&
                clientName.invalid
            }" required />
          <div *ngIf="
              clientName.touched &&
              clientName.errors?.required
            " class="invalid-feedback">
            Client name is required.
          </div>
          <div *ngIf="clientName.errors?.clientExists" class="invalid-feedback">
            This client name already exists. Please use a different name.
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel')">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary">Submit</button>
    </div>
  </form>
</div>