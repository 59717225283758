import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, catchError, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Client } from '../../../shared/models/client.model';
import {
  CornerstoneApiResponse,
  HttpOptions,
} from '../../../shared/models/http.model';
import { Store } from '../../../shared/models/store.model';
import { ErrorHandlerService } from '../../../shared/services/error-handler.service';
import { HttpService } from '../../../shared/services/http.service';
import { SubscriptionWrappers } from '../../../shared/services/subscription-wrappers';

const API_URL = environment.apiUrl;
const NEST_API_URL = environment.nestApiUrl;

@Injectable({
  providedIn: 'root',
})
export class ClientService implements OnDestroy {
  private httpOptions: HttpOptions;
  protected _currentClient = new Subject<Client>();
  public currentClient$ = this._currentClient.asObservable();
  private _clients = new Subject<Client[]>();
  public clients$ = this._clients.asObservable();
  private subwrapper = new SubscriptionWrappers();

  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    private router: Router,
    private errorHandler: ErrorHandlerService
  ) {
    this.httpOptions = this.httpService.getHttpOptions();
  }

  ngOnDestroy(): void {
    this.subwrapper.cleanup();
  }

  public setCurrentClient(client: Client) {
    this._currentClient.next(client);
    sessionStorage.setItem(
      environment.currentClientKey,
      JSON.stringify(client)
    );
  }

  public getCurrentClient(reload = false): Client {
    try {
      const clientData = sessionStorage.getItem(environment.currentClientKey);
      if (clientData) {
        const clientModel: Client = JSON.parse(clientData);
        this._currentClient.next(clientModel); // Notify subscribers with the latest client information
        return clientModel;
      }
    } catch (error) {
      console.error('Failed to parse client data:', error);
    }

    // Navigate to start if no client data is found, which might indicate session issues or first-time access
    this.router.navigate(['/start']);
    return null;
  }

  public getClients(
    tenantId: string
  ): Observable<CornerstoneApiResponse<Client[]>> {
    this.httpClient
      .get(`${environment.apiUrl}/clients/${tenantId}`, this.httpOptions)
      .subscribe({
        next: (res: any) => {
          const clients: Client[] = res.data.map((clientData: any) => {
            const client: Client = new Client();
            client.setClient(clientData);
            return client;
          });
          this._clients.next(clients);
        },
        error: (err: any) => {
          this.errorHandler.handleError(err);
          this._clients.error(err);
        },
      });

    return this.httpClient.get<CornerstoneApiResponse<Client[]>>(
      `${environment.apiUrl}/clients/${tenantId}`,
      this.httpOptions
    );
  }

  public getClientStores(clientId: string): Observable<Store[]> {
    return this.httpClient
      .get<CornerstoneApiResponse<Store[]>>(
        `${NEST_API_URL}/client/${clientId}/stores`,
        this.httpOptions
      )
      .pipe(map((res) => res.data));
  }

  public getClients$(): Observable<Client[]> {
    return this.clients$;
  }

  public createClient(
    clientName: string,
    tenantId: string
  ): Observable<Client> {
    let body = {
      name: clientName,
      tenantId: tenantId,
    };
    return this.httpClient
      .post<CornerstoneApiResponse<Client>>(
        API_URL + '/client',
        body,
        this.httpOptions
      )
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }
}
