<div class="fondo d-flex flex-column flex-root">
  <div class="d-flex flex-column flex-center flex-column-fluid">
    <div class="d-flex flex-column flex-center text-center p-10">
      <div class="card card-flush w-lg-650px py-5">
        <div class="card-body py-15 py-lg-20">
          <div class="mb-14">
            <a href="#" class="">
              <img
                alt="Logo"
                src="../../../assets/media/logos/logo-black.png"
                class="h-40px"
              />
            </a>
          </div>
          <div>
            <h1 class="fw-bolder text-gray-900 mb-5">{{ title }}</h1>
            <div class="fs-6 mb-8">
              <span class="fw-semibold text-gray-500"> {{ description }}</span>
            </div>
            <div class="mb-0" *ngIf="verifiedStatus === 'pending'">
              <img
                [src]="src"
                class="mw-100 mh-300px theme-light-show"
                alt=""
              />
            </div>
            <div class="mb-0" *ngIf="verifiedStatus === 'verified'">
              <img
                [src]="src"
                class="mw-100 mh-300px theme-light-show"
                alt=""
              />
            </div>
            <div class="mb-0" *ngIf="verifiedStatus === 'error'">
              <img
                [src]="src"
                class="mw-100 mh-300px theme-light-show"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
