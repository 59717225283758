import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.dismiss()">Cancel</button>
      <button type="button" [class]="'btn ' + confirmButtonClass" (click)="modal.close('confirm')">
        {{ confirmButtonText }}
      </button>
    </div>
  `
})
export class ConfirmationDialogComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() confirmButtonText = 'Confirm';
  @Input() confirmButtonClass = 'btn-primary';

  constructor(public modal: NgbActiveModal) {}
} 