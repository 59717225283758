import { Component } from '@angular/core';

@Component({
  selector: 'app-activity-drawer',
  templateUrl: './activity-drawer.component.html',
  styleUrls: ['./activity-drawer.component.scss']
})
export class ActivityDrawerComponent {

}
