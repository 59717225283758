import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { WebhookState, WebhookSubscription, WebhookTopic } from '../../../../../shared/models/webhook.model';
import { WebhookService } from '../../../../../shared/services/webhook.service';

@Component({
  selector: 'app-create-webhook',
  templateUrl: './create-webhook.component.html',
  styleUrls: ['./create-webhook.component.scss']
})
export class CreateWebhookComponent implements OnInit {
  @Output() webhookCreated = new EventEmitter<void>();
  @Input() isEdit: boolean = false;
  @Input() webhook: WebhookSubscription;
  @Input() existingWebhooks: WebhookSubscription[] = [];
  
  webhookForm: FormGroup;
  isLoading = false;
  topics = Object.values(WebhookTopic);
  availableTopics: WebhookTopic[] = [];

  constructor(
    private fb: FormBuilder,
    private webhookService: WebhookService,
    private activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.availableTopics = this.isEdit ? this.topics : this.topics.filter(topic => !this.existingWebhooks.some(existing => existing.topic === topic));
    this.initForm();
  }

  private initForm(): void {
    this.webhookForm = this.fb.group({
      topic: [{
        value: '',
        disabled: this.isEdit
      }, Validators.required],
      url: ['', [Validators.required, Validators.pattern('https?://.+')]],
      state: [true]
    });

    if (this.isEdit && this.webhook) {
      this.webhookForm.patchValue({
        topic: this.webhook.topic,
        url: this.webhook.url,
        state: this.webhook.state === WebhookState.ACTIVE
      });
    }

    this.updateAvailableTopics();
  }

  private updateAvailableTopics(): void {
    this.availableTopics = this.topics.filter(topic => 
      !this.webhookService.isTopicUsed(topic)
    );
  }

  onSubmit(): void {
    if (this.webhookForm.invalid) {
      return;
    }

    const formValue = this.webhookForm.value;
    const webhookData = {
      ...formValue,
      state: formValue.state ? WebhookState.ACTIVE : WebhookState.DISABLED
    };

    this.isLoading = true;
    
    if (this.isEdit) {
      this.webhookService.updateWebhook('atomix', this.webhook.id, webhookData).subscribe({
        next: () => {
          this.webhookCreated.emit();
          this.activeModal.close();
        },
        error: (error) => {
          const errorMessage = error.error?.message || 'Failed to update webhook';
          this.toastr.error(errorMessage);
          this.isLoading = false;
        }
      });
    } else {
      this.webhookService.createWebhook('atomix', webhookData).subscribe({
        next: () => {
          this.webhookCreated.emit();
          this.activeModal.close();
          this.updateAvailableTopics();
        },
        error: (error) => {
          const errorMessage = error.error?.message || 'Failed to create webhook';
          this.toastr.error(errorMessage);
          this.isLoading = false;
        }
      });
    }
  }

  close(): void {
    this.activeModal.dismiss();
  }
} 