<div class="datetime-picker d-flex align-items-center">
  <div class="input-group">
    <button
      class="btn btn-outline-secondary bi bi-calendar3"
      (click)="datepicker.toggle()"
      type="button"
    ></button>
    <input
      class="form-control"
      [formControl]="dateControl"
      readonly
      ngbDatepicker
      #datepicker="ngbDatepicker"
      [minDate]="dateConfig.minDate"
      [maxDate]="dateConfig.maxDate"
      [markDisabled]="isDisabled"
    />
  </div>
  <ngb-timepicker
    [formControl]="timeControl"
    [spinners]="false"
  ></ngb-timepicker>
</div>
