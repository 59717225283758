import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor(private router: Router) {}

  handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('authToken:token');
      document.location.reload();
      // this.router.navigate(['/auth/login']);
    }
    throw error;
  }
}
