<div class="modal-header">
  <h4 class="modal-title">{{ isEdit ? 'Edit Webhook' : 'Create New Webhook' }}</h4>
  <button type="button" class="btn-close" (click)="close()"></button>
</div>

<form [formGroup]="webhookForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="mb-4">
      <label class="form-label required">Topic</label>
      <select class="form-select" formControlName="topic" [disabled]="isEdit">
        <option value="">Select a topic</option>
        <option *ngFor="let topic of availableTopics" [value]="topic">{{ topic }}</option>
      </select>
      <div class="text-danger small mt-1" *ngIf="webhookForm.get('topic')?.errors?.required && webhookForm.get('topic')?.touched">
        Topic is required
      </div>
    </div>

    <div class="mb-4">
      <label class="form-label required">Webhook URL</label>
      <input 
        type="url" 
        class="form-control" 
        formControlName="url" 
        placeholder="https://..."
      >
      <div class="text-danger small mt-1" *ngIf="webhookForm.get('url')?.errors?.required && webhookForm.get('url')?.touched">
        URL is required
      </div>
      <div class="text-danger small mt-1" *ngIf="webhookForm.get('url')?.errors?.pattern && webhookForm.get('url')?.touched">
        Please enter a valid URL
      </div>
    </div>

    <div class="mb-4">
      <label class="form-label">Initial State</label>
      <div class="form-check form-switch">
        <input 
          class="form-check-input" 
          type="checkbox" 
          formControlName="state"
        >
        <label class="form-check-label">
          {{ webhookForm.get('state')?.value ? 'Active' : 'Disabled' }}
        </label>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
    <button 
      type="submit" 
      class="btn btn-primary" 
      [disabled]="webhookForm.invalid || isLoading || availableTopics.length === 0"
      *ngIf="availableTopics.length > 0"
    >
      <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-2"></span>
      {{ isEdit ? 'Update Webhook' : 'Create Webhook' }}
    </button>
  </div>
</form> 