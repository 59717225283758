import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ShopifyAuthVerifyComponent } from './components/shopify-auth-verify/shopify-auth-verify.component';

@NgModule({
  declarations: [
    ForgotPasswordComponent,
    LoginComponent,
    LogoutButtonComponent,
    ShopifyAuthVerifyComponent,
    AuthenticationComponent,
    RegistrationComponent,
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  exports: [LogoutButtonComponent, RegistrationComponent],
})
export class AuthenticationModule {}
