<!--begin::Logo-->
<app-sidebar-logo
  class="app-sidebar-logo px-6"
  id="kt_app_sidebar_logo"
  [toggleButtonClass]="toggleButtonClass"
  [toggleEnabled]="toggleEnabled"
  [toggleType]="toggleType"
  [toggleState]="toggleState"
>
</app-sidebar-logo>
<!--end::Logo-->

<!--begin::sidebar menu-->
<app-sidebar-menu class="app-sidebar-menu overflow-hidden flex-column-fluid">
</app-sidebar-menu>
<!--end::sidebar menu-->

<!--begin::Footer-->
<app-sidebar-footer
  class="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
  id="kt_app_sidebar_footer"
>
</app-sidebar-footer>
<!--end::Footer-->
