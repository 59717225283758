<div class="d-flex flex-column flex-lg-row flex-column-fluid bg-black">
  <div
    class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1"
  >
    <div class="d-flex flex-center flex-column flex-lg-row-fluid">
      <div class="<?php echo $params['wrapperClass']?> p-10">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
    [ngStyle]="{ 'background-image': 'url(./assets/media/misc/auth-bg.png)' }"
  >
    <div class="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
      <img
        class="mx-auto w-120px w-md-50 w-xl-350px mb-10 mb-lg-20"
        src="./assets/media/auth/rocket_transparent.png"
        alt=""
      />
      <h1 class="text-white fs-2qx fw-bolder text-center mb-7">
        Welcome to
        <img
          alt="Logo"
          src="./assets/media/logos/logo-black.png"
          class="h-75px"
        />
      </h1>
      <div class="text-white fs-base text-center">
        "Welcome to our integrated e-commerce platform! Sign in to your account
        and enjoy a seamless shopping experience across various services."
      </div>
    </div>
  </div>
</div>
