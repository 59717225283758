import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  Auth,
  GoogleAuthProvider,
  User,
  UserCredential,
  getRedirectResult,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  catchError,
  finalize,
  from,
  map,
  of,
  switchMap,
} from 'rxjs';
import { UserModel } from 'src/app/features/authentication/models/user.model';
import { environment } from 'src/environments/environment';
import { AuthToken as AuthModel } from '../../../shared/models/auth.model';
import { CornerstoneApiResponse } from '../../../shared/models/http.model';
import { Store } from '../../../shared/models/store.model';
import { HttpService } from '../../../shared/services/http.service';
import { SubscriptionWrappers } from '../../../shared/services/subscription-wrappers';
import { AuthHTTPService } from './auth-http.service';

export type UserType = UserModel | undefined;
const API_URL = `${environment.apiUrl}`;
const authLocalStorageToken = `authToken`;
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  public userAuth: Observable<User | null> = EMPTY;
  protected _currentUser = new BehaviorSubject<UserModel>(null);
  public currentUser$ = this._currentUser.asObservable();
  protected _currentAuth = new BehaviorSubject<AuthModel>(null);
  public currentAuth$ = this._currentAuth.asObservable();
  private subwrapper = new SubscriptionWrappers();
  protected _httpOptions = new BehaviorSubject<any>(null);
  public httpOptions$ = this._httpOptions.asObservable();
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  redirectUrl: any;

  // Getter for _currentUser
  get currentUser(): Observable<UserModel | null> {
    return this._currentUser.asObservable();
  }

  get currentUserValue(): UserModel {
    return this._currentUser.value;
  }

  set currentUserValue(user: UserModel) {
    this._currentUser.next(user);
  }

  get currentAuthValue(): AuthModel {
    return this._currentAuth.value;
  }
  set currentAuthValue(authModel: AuthModel) {
    this._currentAuth.next(authModel);
  }

  get httpOptionsValue(): any {
    return this._httpOptions.value;
  }

  set httpOptionsValue(httpOptions: any) {
    this._currentAuth.next(httpOptions);
  }

  constructor(
    private auth: Auth,
    private router: Router,
    private httpService: HttpService,
    private http: HttpClient,
    private authHttpService: AuthHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
  }

  isUserLoggedIn() {
    const currentUser = this.currentUserValue;
    if (currentUser) {
      // logged in so return true
      return true;
    }

    return false;
  }

  private setOptionsHeader(accessToken: string): any {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${accessToken}`)
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*');
    const options = {
      headers: headers,
    };
    this._httpOptions.next(options);
  }

  signInWithGoogleRedirect(): Promise<void> {
    localStorage.setItem('isGoogleLoading', 'true');
    const provider = new GoogleAuthProvider();
    return signInWithRedirect(this.auth, provider);
  }

  getGoogleRedirectResult(): Observable<UserCredential | null> {
    localStorage.setItem('isGoogleLoading', 'false');
    const authModel = new AuthModel();
    const user = new UserModel();
    return from(getRedirectResult(this.auth)).pipe(
      switchMap((userCredential) => {
        if (userCredential) {
          const oauthCredential =
            GoogleAuthProvider.credentialFromResult(userCredential);
          const accessToken = oauthCredential.accessToken;
          user.setUser(userCredential.user);

          // Handle successful Google login here
          // For example, you can update your app's state and trigger further actions

          // Update the current user
          this._currentUser.next(user);

          // Now, perform additional actions similar to signInWithEmailAndPassword
          // For example, set authentication, make an HTTP request, and store data

          // Set authentication and make HTTP request
          authModel.setAuth({
            refreshToken: userCredential.user.refreshToken,
            accessToken: accessToken,
          });
          return this.httpService.login(user.email, authModel.accessToken);
        }

        return of(userCredential);
      }),
      switchMap((res) => {
        // Continue with additional actions
        if (res) {
          // Set current auth
          this._currentAuth.next(authModel);
          // Set options header
          this.setOptionsHeader(authModel.accessToken);
          // Store data to local storage
          this.setAuthToLocalStorage(authModel);
        } else {
          this.logout();
        }
        return of(res);
      }),
      catchError((err) => {
        this.logout();
        // Replace with whatever value or observable we want to
        // emit instead of the error.
        return of(null);
      })
    );
  }
  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getUserByToken(): Observable<UserModel> {
    const authModel = this.getAuthFromLocalStorage();
    if (!authModel?.accessToken) {
      return of(undefined);
    }
    return this.httpService.getUserByToken(authModel.accessToken).pipe(
      map((res: any) => {
        let user: UserModel = new UserModel();
        user.setUser(res.data.user);
        if (user.email) {
          this._currentUser.next(user);
        } else {
          this.logout();
        }
        return res;
      })
    );
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const authData = localStorage.getItem(authLocalStorageToken);
      if (!authData) {
        return undefined;
      }
      const authModel: AuthModel = JSON.parse(authData);
      this.setOptionsHeader(authModel.accessToken);
      return authModel;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public login(email: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let user: UserModel = new UserModel();
      let authModel: AuthModel = new AuthModel();
      signInWithEmailAndPassword(this.auth, email, password)
        .then((userCredential: UserCredential) => {
          let userCred: any = userCredential.user;
          user.setUser(userCredential.user);
          authModel.setAuth(userCred.stsTokenManager);
          return this.httpService.login(user.email, authModel.accessToken);
        })
        .then((res) => {
          this._currentAuth.next(authModel);
          this._currentUser.next(user);
          this.setOptionsHeader(authModel.accessToken);
          const result = this.setAuthToLocalStorage(authModel);

          // Redirect logic after successful login
          if (this.redirectUrl) {
            const redirect = this.redirectUrl;
            this.redirectUrl = null; // Clear the redirectUrl after using it
            // Perform the redirect after login
            // Use your router or navigation service here to redirect
            // For example, if you're using Angular's Router:
            this.router.navigateByUrl(redirect);
          } else {
            // If there's no redirect URL stored, go to a default route
            this.router.navigate(['/start']); // Replace with your default route
          }

          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  logout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authToken:token');
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
    document.location.reload();
  }

  forgotPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  private setAuthToLocalStorage(authModel: AuthModel): boolean {
    if (authModel?.accessToken) {
      localStorage.setItem(authLocalStorageToken, JSON.stringify(authModel));
      localStorage.setItem(
        authLocalStorageToken + ':token',
        JSON.stringify(authModel.accessToken)
      );
      return true;
    }
    return false;
  }

  public shopifyAuthVerify(body): Observable<Store> {
    return this.http
      .post<CornerstoneApiResponse<Store>>(
        API_URL + '/auth/shopify/verify',
        body,
        this.httpService.getHttpOptions()
      )
      .pipe(map((res) => res.data));
  }

  ngOnDestroy() {
    this.subwrapper.cleanup();
  }
}
