import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LoggerService } from 'src/app/shared/services/logger.service';

@Component({
  selector: 'app-check-options',
  templateUrl: './check-options.component.html',
  styleUrls: ['./check-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckOptionsComponent),
      multi: true,
    },
  ],
})
export class CheckOptionsComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() title: string;
  @Input() disabled = false;
  @Input() type: 'single' | 'multiple';
  @Input() options: { [key: string]: string } = {};
  @Input() optionSelected: string = ''; // Reintroduced input
  @Input() optionsSelected: string[] = [];
  @Input() show: boolean = true;
  @Output() onChange = new EventEmitter<string[]>();

  public displayOptions: Array<{
    value: string;
    displayValue: string;
    checked: boolean;
  }> = [];

  constructor(private logger: LoggerService) { }

  ngOnInit(): void {
    this.updateDisplayOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options || changes.optionsSelected) {
      this.updateDisplayOptions();
    }
  }

  private updateDisplayOptions(): void {
    this.displayOptions = Object.entries(this.options).map(
      ([value, displayValue]) => ({
        value,
        displayValue,
        checked: this.isSelected(value),
      })
    );
  }

  private isSelected(value: string): boolean {
    return this.type === 'single'
      ? this.optionSelected === value || this.optionsSelected[0] === value
      : this.optionsSelected.includes(value);
  }

  // ControlValueAccessor Members
  onChangeFn: (value: string | string[]) => void = () => { };
  onTouchedFn: () => void = () => { };

  writeValue(value: any): void {
    if (this.type === 'single') {
      this.optionSelected = value || '';
    } else {
      this.optionsSelected = value || [];
    }
    this.updateDisplayOptions(); // Make sure the UI is updated
  }

  registerOnChange(fn: (value: string | string[]) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Optionally implement to handle the disabled state
  }

  changeValue(event: Event): void {
    const element = event.target as HTMLInputElement;
    const value = element.value;

    if (this.type === 'single') {
      this.optionSelected = value;
      this.optionsSelected = [value]; // Replace the array with the single selected value
      this.onChangeFn(this.optionsSelected); // Notify form bindings
    } else {
      this.handleMultipleSelectionChange(value);
    }

    this.onTouchedFn();
    this.onChange.emit(this.optionsSelected); // Ensure external bindings are updated
  }

  private handleMultipleSelectionChange(value: string): void {
    let currentValues = new Set(this.optionsSelected);
    if (currentValues.has(value)) {
      currentValues.delete(value);
    } else {
      currentValues.add(value);
    }
    const newValue = Array.from(currentValues);
    this.optionsSelected = newValue;
    this.onChangeFn(newValue);
  }
}
