import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  NgbDropdownModule,
  NgbProgressbarModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { RouterModule } from '@angular/router';
import { AuthenticationModule } from '../features/authentication/authentication.module';
import { SharedModule } from '../shared/shared.module';
import { AsideMenuComponent } from './components/aside/aside-menu/aside-menu.component';
import { AsideComponent } from './components/aside/aside.component';
import { ContentComponent } from './components/content/content.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { InitializationComponent } from './components/initialization/initialization.component';
import { LayoutComponent } from './components/layout/layout.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { LayoutScrollTopComponent } from './components/scroll-top/layout-scroll-top.component';
import { SidebarFooterComponent } from './components/sidebar/sidebar-footer/sidebar-footer.component';
import { SidebarLogoComponent } from './components/sidebar/sidebar-logo/sidebar-logo.component';
import { SidebarMenuComponent } from './components/sidebar/sidebar-menu/sidebar-menu.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AccountingComponent } from './components/toolbar/accounting/accounting.component';
import { ClassicComponent } from './components/toolbar/classic/classic.component';
import { ExtendedComponent } from './components/toolbar/extended/extended.component';
import { ReportsComponent } from './components/toolbar/reports/reports.component';
import { SaasComponent } from './components/toolbar/saas/saas.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    HeaderMenuComponent,
    ToolbarComponent,
    AccountingComponent,
    ClassicComponent,
    ExtendedComponent,
    ReportsComponent,
    SaasComponent,
    PageTitleComponent,
    ContentComponent,
    FooterComponent,
    InitializationComponent,
    SidebarComponent,
    SidebarMenuComponent,
    SidebarLogoComponent,
    SidebarFooterComponent,
    AsideComponent,
    AsideMenuComponent,
    LayoutScrollTopComponent,
  ],
  imports: [
    AuthenticationModule,
    RouterModule,
    CommonModule,
    TranslateModule,
    InlineSVGModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    TranslateModule,
    SharedModule,
  ],
  exports: [],
})
export class CoreModule {}
