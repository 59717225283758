import { Component } from '@angular/core';

@Component({
  selector: 'app-tenant-overview',
  templateUrl: './tenant-overview.component.html',
  styleUrls: ['./tenant-overview.component.scss']
})
export class TenantOverviewComponent {

}
