<div class="multi-choice-input">
  <div *ngFor="let option of displayOptions" class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      [id]="'multi-choice' + '_' + option.value"
      [disabled]="readOnly"
      [checked]="option.checked"
      (change)="toggleOption(option)"
    />
    <label
      class="form-check-label ps-2"
      [for]="'multi-choice' + '_' + option.value"
    >
      {{ option.displayValue }}
    </label>
  </div>
</div>
