import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from '../../../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { WebhookSubscription, WebhookTopic } from '../../../../../shared/models/webhook.model';
import { WebhookService } from '../../../../../shared/services/webhook.service';
import { CreateWebhookComponent } from '../create-webhook/create-webhook.component';
@Component({
  selector: 'app-webhooks-list',
  templateUrl: './webhooks-list.component.html',
  styleUrls: ['./webhooks-list.component.scss']
})
export class WebhooksListComponent implements OnInit {
  webhooks: WebhookSubscription[] = [];
  isLoading = false;
  isToggling: number | null = null;

  constructor(
    private webhookService: WebhookService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadWebhooks();
  }

  loadWebhooks(): void {
    this.isLoading = true;
    this.webhookService.getWebhooks('atomix').subscribe({
      next: (webhooks) => {
        this.webhooks = webhooks;
        this.isLoading = false;
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.toastr.error('Failed to load webhooks');
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    });
  }

  openCreateModal(): void {
    const modalRef = this.modalService.open(CreateWebhookComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'modal-custom',
      modalDialogClass: 'modal-dialog-centered'
    });

    modalRef.componentInstance.webhookCreated.subscribe(() => {
      this.loadWebhooks();
      this.toastr.success('Webhook created successfully');
    });

    modalRef.result.then(
      () => {
        this.loadWebhooks();
      },
      () => {
        this.loadWebhooks();
      }
    );
  }

  toggleWebhook(webhook: WebhookSubscription): void {
    this.isToggling = webhook.id;
    this.webhookService.toggleTenantWebhook('atomix', webhook.id).subscribe({
      next: () => {
        this.loadWebhooks();
        this.toastr.success('Webhook state updated successfully');
        this.isToggling = null;
      },
      error: (error) => {
        this.toastr.error(error.message || 'Failed to update webhook state');
        this.isToggling = null;
      }
    });
  }

  deleteWebhook(webhook: WebhookSubscription): void {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {
      centered: true
    });
    
    modalRef.componentInstance.title = 'Delete Webhook';
    modalRef.componentInstance.message = `Are you sure you want to delete the webhook for ${webhook.topic}?`;
    modalRef.componentInstance.confirmButtonText = 'Delete';
    modalRef.componentInstance.confirmButtonClass = 'btn-danger';

    modalRef.result.then((result) => {
      if (result === 'confirm') {
        this.webhookService.deleteWebhook(webhook.id).subscribe({
          next: () => {
            this.webhooks = this.webhooks.filter(w => w.id !== webhook.id);
            this.toastr.success('Webhook deleted successfully');
            this.loadWebhooks();
            this.cdr.detectChanges();
          },
          error: (error) => {
            this.toastr.error('Failed to delete webhook');
          }
        });
      }
    });
  }

  editWebhook(webhook: WebhookSubscription): void {
    const modalRef = this.modalService.open(CreateWebhookComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'modal-custom',
      modalDialogClass: 'modal-dialog-centered'
    });

    modalRef.componentInstance.isEdit = true;
    modalRef.componentInstance.webhook = { ...webhook };

    modalRef.componentInstance.webhookCreated.subscribe(() => {
      this.loadWebhooks();
      this.toastr.success('Webhook updated successfully');
    });
  }
  
  areAllWebhooksCreated(): boolean {
    const uniqueTopics = Object.values(WebhookTopic);
    return uniqueTopics.every(topic => 
        this.webhooks.some(webhook => webhook.topic === topic)
    );
}

} 