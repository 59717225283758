<div class="px-7 py-5">
  <div class="fs-5 text-dark fw-bolder">Filter Options</div>
</div>

<div class="separator border-gray-200"></div>

<div class="px-7 py-5">
  <div class="mb-10">
    <label class="form-label fw-bold">Status:</label>

    <div>
      <select
        class="form-select form-select-solid"
        data-kt-select2="true"
        data-placeholder="Select option"
        data-allow-clear="true"
      >
        <option></option>
        <option selected value="1">Approved</option>
        <option value="2">Pending</option>
        <option value="3">In Process</option>
        <option value="4">Rejected</option>
      </select>
    </div>
  </div>

  <div class="mb-10">
    <label class="form-label fw-bold">Member Type:</label>

    <div class="d-flex">
      <label
        class="form-check form-check-sm form-check-custom form-check-solid me-5"
      >
        <input class="form-check-input" type="checkbox" value="1" />
        <span class="form-check-label">Author</span>
      </label>

      <label
        class="form-check form-check-sm form-check-custom form-check-solid"
      >
        <input
          class="form-check-input"
          type="checkbox"
          value="2"
          defaultChecked="{true}"
        />
        <span class="form-check-label">Customer</span>
      </label>
    </div>
  </div>

  <div class="mb-10">
    <label class="form-label fw-bold">Notifications:</label>

    <div
      class="form-check form-switch form-switch-sm form-check-custom form-check-solid"
    >
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        name="notifications"
        defaultChecked="{true}"
      />
      <label class="form-check-label">Enabled</label>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <button
      type="reset"
      class="btn btn-sm btn-light btn-active-light-primary me-2"
      data-kt-menu-dismiss="true"
    >
      Reset
    </button>

    <button
      type="submit"
      class="btn btn-sm btn-primary"
      data-kt-menu-dismiss="true"
    >
      Apply
    </button>
  </div>
</div>
