import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import Notiflix from 'notiflix';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { SubscriptionWrappers } from 'src/app/shared/services/subscription-wrappers';

@Component({
  selector: 'app-multi-value-input',
  templateUrl: './multi-value-input.component.html',
  styleUrls: ['./multi-value-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiValueInputComponent),
      multi: true,
    },
  ],
})
export class MultiValueInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() values: string[] = [];
  @Input() title: string;
  @Input() readOnly: boolean;
  @Output() valuesChange = new EventEmitter<string[]>();
  inputValue: string = '';

  onChange: any = () => {};
  formControl: FormControl;
  showError: boolean;
  private subwrapper: SubscriptionWrappers = new SubscriptionWrappers();
  onTouched: any;
  hasError: EventEmitter<boolean>;

  constructor(private logger: LoggerService) {}

  ngOnInit(): void {
    Notiflix.Notify.init({
      width: '280px',
      position: 'right-top',
      distance: '10px',
      opacity: 1,
      borderRadius: '5px',
      rtl: false,
      timeout: 3000,
      messageMaxLength: 110,
      fontSize: '13px',
      cssAnimationStyle: 'from-top',
      info: {
        background: '#28a745',
        textColor: '#fff',
        childClassName: 'custom-info',
      },
    });
  }

  setControl(control: FormControl) {
    this.formControl = control;
    this.formControl.valueChanges.subscribe((value) => {
      this.writeValue(value);
    });

    this.formControl.statusChanges.subscribe((status) => {
      this.showError =
        status === 'INVALID' && this.formControl.errors?.required;
    });

    this.registerOnChange((value: string[]) =>
      this.formControl.setValue(value)
    );
    this.registerOnTouched(() => this.formControl.markAsTouched());
  }

  listenForEdit(editControl: FormControl) {
    editControl.valueChanges.subscribe((value: boolean) => {
      this.readOnly = !value;
    });
  }

  writeValue(values: string[]): void {
    if (values) {
      this.values = values;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get isValid(): boolean {
    return this.formControl?.valid ?? true;
  }

  get isTouched(): boolean {
    return this.formControl?.touched ?? false;
  }

  addValue(event: Event): void {
    const trimmedValue = this.inputValue.trim();
    if (trimmedValue) {
      this.values.push(trimmedValue);
      this.inputValue = '';
      this.showError = false;
      this.updateFormControl();
    } else if (this.values?.length > 0) {
      this.showError = false;
    } else {
      this.showError = true;
    }
  }

  updateFormControl(): void {
    this.valuesChange.emit(this.values);
    this.onChange(this.values);
    this.onTouched();
  }

  removeValue(index: number): void {
    this.values.splice(index, 1);
    this.updateFormControl();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.addValue(event);
    }
  }

  onTouch(): void {
    if (this.values?.length <= 0) {
      this.showError = true;
    }
    this.onTouched();
  }

  onBlur(event: Event): void {
    this.addValue(event);
  }

  checkForError(): void {
    if (this.values.length === 0) {
      this.hasError.emit(true);
    } else {
      this.hasError.emit(false);
    }
  }

  ngOnDestroy(): void {
    this.subwrapper.cleanup();
  }
}
