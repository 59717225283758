import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { map } from 'rxjs';
import { ClientService } from '../services/client.service';

export class ClientValidator {
  static createUniqueClientValidator(
    clientService: ClientService,
    tenantId: string
  ): AsyncValidatorFn {
    return (control: AbstractControl) => {
      const clientName: string = control.value;
      return clientService.getClients(tenantId).pipe(
        map((response) => {
          const clientNames = response.data.map((c) => c.name);
          const client = clientNames.find((c) => c == clientName);
          return client ? { clientExists: true } : null;
        })
      );
    };
  }
}
