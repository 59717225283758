import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  Auth,
  connectAuthEmulator,
  getAuth,
  provideAuth,
  useDeviceLanguage,
} from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ClipboardModule } from 'ngx-clipboard';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { TimeoutInterceptor } from './core/interceptors/timeout.interceptor';
import { AuthService } from './features/authentication/services/auth.service';

function appInitializer(auth: Auth, authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      if (localStorage.getItem('isGoogleLoading') === 'true') {
        //@ts-ignore
        authService.getGoogleRedirectResult().subscribe(() => resolve(null));
      } else {
        authService
          .getUserByToken()
          .subscribe()
          .add(() => resolve(null));
      }
    });
  };
}
const config: SocketIoConfig = { url: environment.apiUrl, options: {} };

@NgModule({
  declarations: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [Auth, AuthService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    NgxMatMomentModule,
    AppRoutingModule,
    SocketIoModule.forRoot(config),
    InlineSVGModule.forRoot(),
    NgbModule,
    NgbModalModule,
    FormsModule,
    ReactiveFormsModule,
    provideFirebaseApp(() => {
      return initializeApp(environment.firebase);
    }),
    provideAuth(() => {
      const auth = getAuth();

      useDeviceLanguage(auth);

      if (environment.useEmulators) {
        connectAuthEmulator(auth, environment.authEmulatorUrl);
      }
      return auth;
    }),
    // provideFirestore(() => getFirestore()),
    provideAnalytics(() => getAnalytics()),
    NgxSpinnerModule.forRoot({ type: 'ball-pulse' }),
    ToastrModule.forRoot(),
    NgSelectModule,
    CoreModule,
  ],
  exports: [
    CommonModule,
    NgbModule,
    NgbModalModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
