import { Component } from '@angular/core';

@Component({
  selector: 'body[root]',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent {
  today: Date = new Date();
}
