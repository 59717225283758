
import { Client } from './client.model';
import { Store } from './store.model';

export enum WebhookTopic {
  ORDER_CREATE = 'ORDER_CREATE',
  ORDER_CANCEL = 'ORDER_CANCEL',
  ORDER_UPDATE = 'ORDER_UPDATE',
  
}

export enum WebhookState {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED'
}

export interface WebhookSubscription {
  id: number;
  topic: WebhookTopic;
  url: string;
  state: WebhookState;
  createdAt: Date;
  updatedAt: Date;
  clientWebhookSubscriptions: ClientWebhookSubscription[];
}

export interface ClientWebhookSubscription {
  id: number;
  webhookSubscription: WebhookSubscription;
  client: Client;
  topic: WebhookTopic;
  state: WebhookState;
  storeWebhookSubscriptions: StoreWebhookSubscription[];
  createdAt: Date;
  updatedAt: Date;
}

export interface StoreWebhookSubscription {
  id: number;
  clientWebhookSubscription: ClientWebhookSubscription;
  store: Store;
  topic: WebhookTopic;
  state: WebhookState;
  createdAt: Date;
  updatedAt: Date;
  isManuallyDisabled: boolean;
} 