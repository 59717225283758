import { Component } from '@angular/core';

@Component({
  selector: 'app-messenger-drawer',
  templateUrl: './messenger-drawer.component.html',
  styleUrls: ['./messenger-drawer.component.scss']
})
export class MessengerDrawerComponent {

}
