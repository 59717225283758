import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(state: RouterStateSnapshot) {
    if (this.authService.isUserLoggedIn()) {
      return true;
    } else {
      // Store the attempted URL to redirect back after successful login
      this.authService.redirectUrl = state.url;
      // Redirect to the login page
      return this.router.createUrlTree(['/auth/login']);
    }
  }
}
