import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from '../../../../features/stores/services/store.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private storeService: StoreService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params) => {
      const hmac = params['hmac'];
      const shop = params['shop'];
      console.log('hmac', hmac);
      console.log('shop', shop);

      if (this.isValidHmac(hmac) && this.isValidShop(shop)) {
        const link = await this.storeService.getShopifyRedirectLink(shop);
        console.log('link', link);
        window.location.href = link;
      } else {
        this.router.navigate(['/tenant']);
      }
    });
  }

  private isValidHmac(hmac: string | undefined): boolean {
    return !!hmac;
  }

  private isValidShop(shop: string | undefined): boolean {
    return !!shop;
  }
}
