<form
  class="form w-100"
  novalidate="novalidate"
  id="kt_login_signup_form"
  [formGroup]="registrationForm"
  (ngSubmit)="submit()"
>
  <!--begin::Heading-->
  <div class="text-center mb-11">
    <!--begin::Title-->
    <h1 class="text-dark fw-bolder mb-3">Sign Up</h1>
    <!--end::Title-->

    <!--begin::Subtitle-->
    <div class="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div>
    <!--end::Subtitle--->
  </div>
  <!--begin::Heading-->

  <!--begin::Login options-->
  <div class="row g-3 mb-9">
    <!--begin::Col-->
    <div class="col-md-6">
      <!--begin::Google link--->
      <a
        href="#"
        class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
      >
        <img
          alt="Logo"
          src="./assets/media/svg/brand-logos/google-icon.svg"
          class="h-15px me-3"
        />
        Sign in with Google
      </a>
      <!--end::Google link--->
    </div>
    <!--end::Col-->

    <!--begin::Col-->
    <div class="col-md-6">
      <!--begin::Google link--->
      <a
        href="#"
        class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
      >
        <img
          alt="Logo"
          src="./assets/media/svg/brand-logos/apple-black.svg"
          class="theme-light-show h-15px me-3"
        />
        <img
          alt="Logo"
          src="./assets/media/svg/brand-logos/apple-black-dark.svg"
          class="theme-dark-show h-15px me-3"
        />
        Sign in with Apple
      </a>
      <!--end::Google link--->
    </div>
    <!--end::Col-->
  </div>
  <!--end::Login options-->

  <!--begin::Separator-->
  <div class="separator separator-content my-14">
    <span class="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
  </div>
  <!--end::Separator-->

  <!-- begin::Alert error-->
  <ng-container *ngIf="hasError">
    <div class="mb-lg-15 alert alert-danger">
      <div class="alert-text font-weight-bold">
        The registration details are incorrect
      </div>
    </div>
  </ng-container>
  <!-- end::Alert error-->

  <!--begin::Form group-->
  <div class="fv-row mb-8">
    <label class="form-label fw-bolder text-dark fs-6">Fullname</label>
    <input
      class="form-control bg-transparent"
      type="text"
      name="fullname"
      formControlName="fullname"
      placeholder="Fullname"
      autocomplete="off"
      [ngClass]="{
        'is-invalid': registrationForm.controls['fullname'].invalid,
        'is-valid': registrationForm.controls['fullname'].valid
      }"
    />
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Fullname is required',
        control: registrationForm.controls['fullname']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'minlength',
        message: 'Fullname should have at least 3 symbols',
        control: registrationForm.controls['fullname']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Fullname should have maximum 100 symbols',
        control: registrationForm.controls['fullname']
      }"
    ></ng-container>
  </div>
  <!--end::Form group-->

  <!--begin::Form group-->
  <div class="fv-row mb-8">
    <label class="form-label fw-bolder text-dark fs-6">Email</label>
    <input
      class="form-control bg-transparent"
      type="email"
      placeholder="Email"
      name="email"
      formControlName="email"
      autocomplete="off"
      [ngClass]="{
        'is-invalid': registrationForm.controls['email'].invalid,
        'is-valid': registrationForm.controls['email'].valid
      }"
    />
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Email is required',
        control: registrationForm.controls['email']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'email',
        message: 'Email is invalid',
        control: registrationForm.controls['email']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'minlength',
        message: 'Email should have at least 3 symbols',
        control: registrationForm.controls['email']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Email should have maximum 360 symbols',
        control: registrationForm.controls['email']
      }"
    ></ng-container>
  </div>
  <!--end::Form group-->

  <!--begin::Form group-->
  <div class="fv-row mb-8">
    <label class="form-label fw-bolder text-dark fs-6">Password</label>
    <input
      class="form-control bg-transparent"
      type="password"
      placeholder="Password"
      name="password"
      formControlName="password"
      autocomplete="off"
      [ngClass]="{
        'is-invalid': registrationForm.controls['password'].invalid,
        'is-valid': registrationForm.controls['password'].valid
      }"
    />
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Password is required',
        control: registrationForm.controls['password']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'minlength',
        message: 'Password should have at least 3 symbols',
        control: registrationForm.controls['password']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Password should have maximum 100 symbols',
        control: registrationForm.controls['password']
      }"
    ></ng-container>
  </div>
  <!--end::Form group-->

  <!--begin::Form group-->
  <div class="fv-row mb-8">
    <label class="form-label fw-bolder text-dark fs-6">Confirm Password</label>
    <input
      class="form-control bg-transparent"
      type="password"
      placeholder="Confirm password"
      name="cPassword"
      autocomplete="off"
      formControlName="cPassword"
      [ngClass]="{
        'is-invalid': registrationForm.controls['cPassword'].invalid,
        'is-valid': registrationForm.controls['cPassword'].valid
      }"
    />
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'required',
        message: 'Confirm Password is required',
        control: registrationForm.controls['cPassword']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'minlength',
        message: 'Confirm Password should have at least 3 symbols',
        control: registrationForm.controls['cPassword']
      }"
    ></ng-container>
    <ng-container
      [ngTemplateOutlet]="formError"
      [ngTemplateOutletContext]="{
        validation: 'maxLength',
        message: 'Confirm Password should have maximum 100 symbols',
        control: registrationForm.controls['cPassword']
      }"
    ></ng-container>
    <ng-container
      *ngIf="
        registrationForm.controls['cPassword'].errors &&
        registrationForm.controls['cPassword'].errors.ConfirmPassword
      "
    >
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          'Passsword' and 'Confirm Password' didn't match.
        </div>
      </div>
    </ng-container>
  </div>
  <!--end::Form group-->

  <!--begin::Form group-->
  <div class="fv-row mb-8">
    <label class="form-check form-check-inline">
      <input
        id="kt_login_toc_agree"
        type="checkbox"
        formControlName="agree"
        name="agree"
        class="form-check-input"
      />
      <span
        for="kt_login_toc_agree"
        class="form-check-label fw-semibold text-gray-700 fs-base ms-1"
      >
        I Accept the&nbsp;<a
          href="https://keenthemes.com/metronic/?page=faq"
          target="_blank"
          class="ms-1 link-primary"
          >Terms</a
        >.
      </span>
    </label>
  </div>
  <!--end::Form group-->

  <!--begin::Form group-->
  <div class="d-grid mb-10">
    <button
      type="submit"
      id="kt_sign_up_submit"
      [disabled]="
        registrationForm.invalid || !registrationForm.controls['agree'].value
      "
      class="btn btn-primary"
    >
      <ng-container *ngIf="(isLoading$ | async) === false">
        <span class="indicator-label">Submit</span>
      </ng-container>
      <ng-container *ngIf="isLoading$ | async">
        <span class="indicator-progress" [style.display]="'block'">
          Please wait...
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </span>
      </ng-container>
    </button>
  </div>
  <!--end::Form group-->

  <!--begin::Sign up-->
  <div class="text-gray-500 text-center fw-semibold fs-6">
    Already have an Account?

    <a routerLink="/auth/login" class="link-primary fw-semibold"> Sign in </a>
  </div>
  <!--end::Sign up-->
</form>

<ng-template
  #formError
  let-control="control"
  let-message="message"
  let-validation="validation"
>
  <ng-container
    *ngIf="control.hasError(validation) && (control.dirty || control.touched)"
  >
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">
          {{ message }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>
