<div *ngFor="let option of options" class="form-check">
  <input
    class="form-check-input"
    type="radio"
    id="'single-choice_' + option.value"
    [value]="option"
    [disabled]="readOnly"
    [(ngModel)]="selected"
    (change)="selectOption(option)"
  />
  <label class="form-check-label" [for]="'single-choice_' + option.value">
    {{ option.displayValue }}
  </label>
</div>
