import { Store } from './store.model';

export class Client {
  uuid: string;
  name: string;
  tenantId: string;
  createdAt: Date;
  updatedAt: Date;
  stores: Store[];

  public setClient(_client: any) {
    Object.assign(this, _client);
  }
}
