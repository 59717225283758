import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from '../../../../features/clients/services/client.service';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {
  clientId: string;

  constructor(private clientService: ClientService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.clientService.currentClient$.subscribe(client => {
      if (client) {
        this.clientId = client.uuid;
        } else {
        console.warn('No client found. Client ID is undefined.');
      }
    });
  }

   

  onNavigate() {
    if (this.clientId) {
      this.router.navigate([`/client/webhooks/${this.clientId}`]);
    } else {
      console.warn('Client ID is not defined. Cannot navigate to webhook subscriptions.');
    
    }
  }
}
