import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, catchError, map, of } from 'rxjs';

import { environment } from '../../../../environments/environment';
import {
  CornerstoneApiResponse,
  HttpOptions,
} from '../../../shared/models/http.model';
import {
  Store,
  StoreConfig,
  StoreCreateRequest,
} from '../../../shared/models/store.model';
import { ErrorHandlerService } from '../../../shared/services/error-handler.service';
import { HttpService } from '../../../shared/services/http.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { SubscriptionWrappers } from '../../../shared/services/subscription-wrappers';

const API_URL = environment.apiUrl;
const NEST_API_URL = environment.nestApiUrl;

@Injectable({
  providedIn: 'root',
})
export class StoreService implements OnDestroy {
  public options: HttpOptions;

  public _store = new Subject<Store>();
  public store$ = this._store.asObservable();
  public subwrapper = new SubscriptionWrappers();

  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    private errorHandler: ErrorHandlerService,
    private logger: LoggerService
  ) {
    this.options = this.httpService.getHttpOptions();
  }

  ngOnDestroy(): void {
    this.subwrapper.cleanup();
  }
  public createStore(store: StoreCreateRequest): Observable<Store> {
    let body = {
      ...store,
    };
    return this.httpClient
      .post<CornerstoneApiResponse<Store>>(
        API_URL + '/stores',
        body,
        this.options
      )
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }

  notifyStore(store: Store) {
    const _store = new Store();
    _store.setStore(store);
    this._store.next(_store);
  }

  public getStore(storeId: string): Observable<Store> {
    return this.httpClient
      .get<CornerstoneApiResponse<Store>>(
        `${NEST_API_URL}/stores/${storeId}`,
        this.options
      )
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }

  /**
   * Update the configuration for the specified store
   * @param storeId store id of the store to update
   * @param config new configuration for the store
   * @returns Observable of response
   */
  public updateConfig(storeId: string, config: StoreConfig): Observable<Store> {
    return this.httpClient
      .put<CornerstoneApiResponse<Store>>(
        `${API_URL}/stores/${storeId}/config`,
        config,
        this.options
      )
      .pipe(
        map((res) => {
          this.notifyStore(res.data);
          return res.data;
        }),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }

  public createFulfillmentService(storeId: string) {
    return this.httpClient
      .post<CornerstoneApiResponse<Store>>(
        `${API_URL}/shopify/fulfillment-service`,
        {
          storeId,
        },
        this.options
      )
      .pipe(
        map((res) => {
          this.notifyStore(res.data);
          return res.data;
        }),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }

  /**
   * Set the access token for the specified store
   * @param storeId store id of the store to update
   * @param token new access token for the store
   * @returns Observable of response
   */
  public setAccessToken(storeId: string, token: string): Observable<Store> {
    return this.httpClient
      .post<CornerstoneApiResponse<Store>>(
        `${API_URL}/stores/${storeId}/access-token`,
        { access_token: token },
        this.options
      )
      .pipe(
        map((res) => {
          this.notifyStore(res.data);
          return res.data;
        }),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }

  public deleteStore(uuid): Observable<any> {
    return this.httpClient
      .delete<any>(API_URL + '/stores/' + uuid, this.options)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          return of(error);
        })
      );
  }

  public generateLink(uid: string): Observable<string> {
    return this.httpClient
      .get<CornerstoneApiResponse<string>>(
        `${API_URL}/stores/${uid}/generate-link`,
        this.options
      )
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          this.errorHandler.handleError(error);
          throw error;
        })
      );
  }
}
